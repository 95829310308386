<template>
    <td><span class="whitespace-nowrap p-2 md:p-0 text-xs text-gray-700">{{ order.supplier_order_number }}</span></td>
    <td><span class="p-2 md:p-0 text-xs text-gray-700">{{ $services.helpers.capitalizeEachString(order.supplier.name) }}</span></td>
    <td>
        <div v-if="order.shipping.length > 0"
                @mouseover="mouseover"
                @mouseleave="mouseleave">
                <!-- <span class="py-2 px-5 md:p-0 text-xs text-gray-700 cursor-pointer" v-if="order.shipping[0].first_name">{{ $services.helpers.capitalizeEachString(order.shipping[0].first_name) }}</span>
                <span class="py-2 px-5 md:p-0 text-xs text-gray-700 cursor-pointer" v-if="order.shipping[0].last_name">{{' ' + $services.helpers.capitalizeEachString(order.shipping[0].last_name) }}</span> -->
                <span class="py-2 px-5 md:p-0 text-xs text-gray-700 cursor-pointer">{{ $services.helpers.capitalizeEachString(order.customer_name) }}</span>
        </div>
        <!-- <div v-else>
            <span class="whitespace-nowrap p-2 md:p-0 text-xs text-gray-700 cursor-pointer"
            @mouseover="mouseover"
            @mouseleave="mouseleave">{{ $t('viewDetails') }}</span>
        </div> -->
        <div class="relative">
            <div class="absolute w-60 bg-white -ml-16 custom-z rounded border shadow-xl top-rem"
                v-show="showCard" @mouseover="cardOver" @mouseleave="cardLeave">
                <div class="flex flex-col">
                    <div class="font-semibold border-b p-2 text-xs text-gray-500">
                        {{ $t('shippingInfo') }}
                    </div>
                    <div class="flex flex-col p-3 text-xs text-gray-500" v-if="order.shipping.length > 0">
                        <span class="flex space-x-1" v-if="order.shipping[0].company">
                            <b>{{ $t('company') }}: </b>&nbsp; <span> {{ order.shipping[0].company }}</span>
                        </span>
                        <span class="flex space-x-1" v-if="order.shipping[0].first_name">
                            <b>{{ $t('customer') }}: </b>&nbsp; <span> {{ $services.helpers.capitalizeEachString(order.shipping[0].first_name) + ' '+ $services.helpers.capitalizeEachString(order.shipping[0].last_name) }}</span>
                        </span>
                        <span class="flex space-x-1" v-if="order.shipping[0].address_1">
                            <b>{{ $t('address') }}: </b>&nbsp; <span> {{ order.shipping[0].address_1 }}</span>
                        </span>
                        <span class="flex space-x-1" v-if="order.shipping[0].address_2">
                            <b>{{ $t('address')}} 2: </b>&nbsp; <span> {{ order.shipping[0].address_2 }}</span>
                        </span>
                        <span class="flex space-x-3">
                        <span v-if="order.shipping[0].city"><b>{{ $t('city') }}: </b>&nbsp; <span> {{ order.shipping[0].city }}</span></span> 
                        <span v-if="order.shipping[0].state"><b>{{ $t('state') }}: </b>&nbsp; <span> {{ order.shipping[0].state }}</span></span> 
                        </span>
                        <span class="flex space-x-1" v-if="order.shipping[0].postcode">
                            <b>{{ $t('postalCode') }}: </b>&nbsp; <span> {{ order.shipping[0].postcode }}</span>
                        </span>
                        <span class="flex space-x-1" v-if="order.shipping[0].phone">
                            <b>{{ $t('phone')}}: </b>&nbsp; <span> {{ order.shipping[0].phone }}</span>
                        </span>
                        <span class="flex space-x-1" v-if="order.shipping[0].country">
                            <b>{{ $t('country') }}: </b>&nbsp; <span> {{ order.shipping[0].country }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </td>
    <td><span class="whitespace-nowrap p-2 md:p-0 text-xs text-gray-700">{{ order.delivery_date }}</span></td>
    <td><span class="whitespace-nowrap p-2 md:p-0 text-xs text-gray-700" v-if="order.date_posted">{{ order.date_posted }}</span></td>
    <td>
        <span v-if="parseFloat(order.items_total)" class="whitespace-nowrap p-2 md:p-0 text-xs text-gray-700">{{ Math.round(order.items_total ) + ' ' + order.currency }}</span>
    </td>
    <td>
        <span class="cursor-pointer" @click="toggleStatus" v-if="!updateStatus">
            <span v-if="order.status && order.status.label === 'PROCESSING'" class="bg-theme-blue font-semibold whitespace-nowrap rounded-md p-1 text-xs text-white">{{ $t('orderProcessing') }}</span>
            <span v-if="order.status && order.status.label === 'PICKING'" class="bg-yellow-500 font-semibold whitespace-nowrap rounded-md p-1 text-xs text-white">{{ $t('picking') }}</span>
            <span v-if="order.status && order.status.label === 'CANCELED'" class="bg-red-500 font-semibold whitespace-nowrap p-1 rounded-md text-xs text-white">{{ $t('orderCancel') }}</span>
            <span v-if="order.status && order.status.label === 'PENDING'" class="bg-yellow-500 font-semibold whitespace-nowrap p-1 rounded-md text-xs text-white">{{ $t('orderPending') }}</span>
            <span v-if="order.status && order.status.label === 'COMPLETED'" class="bg-theme-green font-semibold whitespace-nowrap p-1 rounded-md text-white text-xs">{{ $t('orderCompleted') }}</span>
            <span v-if="order.status && order.status.label === 'IN_PROGRESS'" class="bg-blue-500 font-semibold whitespace-nowrap p-1 rounded-md text-white text-xs">{{ $t('inProgress') }}</span>
            <span v-if="order.status && order.status.label === 'PARTIALLY_FULFILLED'" class="bg-theme-green font-semibold whitespace-nowrap p-1 rounded-md text-white text-xs">{{ $t('partialFulfilled') }}</span>
            <span v-if="order.status && order.status.label === 'FAILED'" class="bg-red-500 font-semibold whitespace-nowrap p-1 rounded-md text-white text-xs">{{ $t('failed') }}</span>
        </span>
    
        <span v-else class="z-50 relative">
            <select v-model="statusChange" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" @change="updateSupplierOrder" required>
                <option :value="item.uuid" v-for="item in shopStatus" :key="item">
                    {{ item.label === 'PROCESSING' ? $t('orderProcessing') : null  }}
                    {{ item.label === 'PICKING' ? $t('picking') : null  }}
                    {{ item.label === 'CANCELED' ? $t('orderCancel') : null  }}
                    {{ item.label === 'PENDING' ? $t('orderPending') : null  }}
                    {{ item.label === 'COMPLETED' ? $t('orderCompleted') : null  }}
                    {{ item.label === 'IN_PROGRESS' ? $t('inProgress') : null  }}
                    {{ item.label === 'PARTIALLY_FULFILLED' ? $t('partialFulfilled') : null  }}
                    {{ item.label === 'FAILED' ? $t('failed') : null  }}
                </option>
            </select>
        </span>

        <!-- <span v-if="order.status && (order.status.label === 'PROCESSING' || order.status.label === 'PICKING')" class="bg-theme-blue font-semibold whitespace-nowrap rounded-md p-1 text-xs text-white">{{ $services.helpers.capitalizeEachString(order.status.label) }}</span>
        <span v-if="order.status && order.status.label === 'CANCELED'" class="bg-red-500 font-semibold whitespace-nowrap p-1 rounded-md text-xs text-white">{{ $services.helpers.capitalizeEachString(order.status.label) }}</span>
        <span v-if="order.status && order.status.label === 'PENDING'" class="bg-yellow-500 font-semibold whitespace-nowrap p-1 rounded-md text-xs text-white">{{ $services.helpers.capitalizeEachString(order.status.label) }}</span>
        <span v-if="order.status && order.status.label === 'COMPLETED'" class="bg-theme-green font-semibold whitespace-nowrap p-1 rounded-md text-white text-xs">{{ $services.helpers.capitalizeEachString(order.status.label) }}</span> -->
        <!-- <span class="bg-theme-green font-semibold whitespace-nowrap p-2 md:p-0 text-xs text-gray-700">{{ order.status.label }}</span> -->
    </td>
    
    <td>
        <div class="flex relative space-x-3 font-semibold whitespace-nowrap p-2 md:p-0 text-sm text-gray-400">
            <div class="absolute w-40 bg-white -ml-16 z-50 rounded border shadow-md top-rem" v-if="showActionBox">
                <div>
                    <ul>
                        <li @click="emitViewEvent" class="hover:bg-deep-teal cursor-pointer hover:text-theme-blue">
                            <span class="flex items-center space-x-1 p-2">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>
                                </span>
                                <span class="text-xs hover:text-theme-blue hover:font-black">{{ $t('viewDetails') }}</span>
                            </span>
                        </li>
                        <li @click="emitUpdateEvent" class="hover:bg-deep-teal cursor-pointer hover:text-theme-blue">
                            <span class="flex items-center space-x-1 p-2">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                    </svg>
                                </span>
                                <span class="text-xs hover:text-theme-blue hover:font-black">{{ $t('editInfo') }}</span>
                            </span>
                        </li>
                        <li @click="emitDeleteEvent" class="hover:bg-deep-teal cursor-pointer hover:text-theme-blue">
                            <span class="flex items-center space-x-1 p-2">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </span>
                                <span class="text-xs hover:text-theme-blue hover:font-black">{{ $t('delete') }}</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <span class="mr-2 cursor-pointer" >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                </svg>
            </span> -->
            <span @click="showActionBox = true" class="flex items-center cursor-pointer space-x-3 font-semibold whitespace-nowrap p-2 md:p-0 text-sm text-gray-400">
                <span class="mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                    </svg>
                </span>
            </span>
        </div>
    </td>
</template>

<script>
import Bus from '../../../bus'
    export default {
        name : 'SupplierOverviewTable',
        props : {
            shopStatus : {
                type : Array,
                require : false
            },
            order : {
                type : Object,
                require : true,
            }
        },
        emits : ['toggleEvent', 'emitDeleteEvent', 'emitViewEvent', 'emitUpdateEvent', 'updateSupplierOrder'],
        data () {
            return {
                showActionBox : false,
                updateStatus : false,
                statusChange : '',
                timeout: null,
                showCard: false,
                isLoaded: false
            }
        },
        watch : {
            showActionBox : function (n, o) {
                if (n) {
                    this.$emit('toggleEvent', true)
                }
            }
        },
        mounted () {
            Bus.on('removeActionBox', e => {
                this.showActionBox = false
            })
            Bus.on('update-sup-status-down', _ => {
                this.updateStatus = false
            })
        },
        methods : {
            toggleStatus () {
                this.updateStatus = !this.updateStatus
                if (this.updateStatus) {
                    Bus.emit('update-sup-status-up')
                }
            },
            updateSupplierOrder (e) {
                this.$emit('updateSupplierOrder', { order : this.order, value : e.target.value })
                this.updateStatus = !this.updateStatus

                // this.updateStatus = !this.updateStatus
                // if (this.updateStatus) {
                //     Bus.emit('update-sup-status-up')
                // }
            },
            emitUpdateEvent () {
                this.$emit('emitUpdateEvent', this.order)
                this.showActionBox = false
            },
            emitViewEvent () {
                this.$emit('emitViewEvent', this.order)
            },
            emitDeleteEvent () {
                this.$emit('emitDeleteEvent', this.order)
                this.showActionBox = false
            },
            mouseover (event) {
                // console.log(event.pageX, event.pageY)
                clearTimeout(this.timeout)
                const self = this
                this.timeout = setTimeout(() => {
                    self.showCard = true
                    setTimeout(() => {
                    self.isLoaded=true;
                    }, 10)
                }, 10)
            },
            mouseleave () {
                const self = this
                this.timeout = setTimeout(() => {
                    self.showCard = false
                    self.isLoaded = false
                }, 90)
            },
            cardOver () {
                clearTimeout(this.timeout);
                this.showCard = true
            },
            cardLeave () {
                const self = this
                this.timeout = setTimeout(() => {
                    self.showCard = false
                    self.isLoaded = false
                }, 0)
            }
        }
    }
</script>

<style lang="scss" scoped>
.table th {
    padding: .3rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.table tbody, td {
    padding: .4rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6 !important;
}
// .top-rem{
//     top: 27rem
// }
.custom-z{
    z-index: 9999;
}
</style>