<template>
    <td><span class="text-gray-600 text-xs">{{ item.product.name.slice(0, 40)+'...' }}</span></td>
    <td>
        <span class="flex justify-center block w-full">
            <input v-model="item.qty" @change="updateQty" type="number" min="1" class="w-1/2 bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
        </span>
    </td>
    <td>
        <span class="flex justify-center block w-full text-gray-600 text-xs">{{ item.unit }}</span>
    </td>
    <td>
        <span class="flex justify-center block w-full text-gray-600 text-xs">{{ parseFloat(item.supplier_price).toFixed(2) }}</span>
    </td>
    <td>
        <!-- @click="setProduct(data, 'delete')" -->
        <span class="flex justify-center block w-full cursor-pointer" @click="removeProduct">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-600" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
        </span>
    </td>
</template>

<script>
    export default {
        name : 'SupplierOrderRow',
        props : {
            data : {
                type : Object,
                require : true
            },
            row : {
                type : Number,
                require : true,
            }
        },
        emits : ['updateQuantity', 'setProduct'],
        data () {
            return { 
                item : this.data
            }
        },
        methods : {
            updateQty (e) {
                if (parseInt(e.target.value) > 0) {
                    this.$emit('updateQuantity', { row : this.row,  value : e.target.value })
                }
            },
            removeProduct () {
                this.$emit('setProduct', { data : this.data })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>